import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { api } from "@/utils/axios";
import { groupBy, filter } from "lodash";
// import router from "@/router/index";
import { showToast, showLoading } from "@/utils/useIonicComponents";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";

export const useLeagueStore = defineStore("league", () => {
  const gameStore = useGameStore();
  const league = ref(null);
  const isLoading = ref(false);
  

  const leagueName = computed(() => {
    if (league.value) {
      return league.value.name;
    }
    return "Loading...";
  });
  const leagueLogo = computed(() => {
    if (league.value?.logo_url) {
      return league.value.logo_url;
    } else {
      return null;
    }
  });
  const leagueBackground = computed(() => {
    if (league.value.background_url) {
      return league.value.background_url;
    } else {
      return null;
    }
  });
  const leagueSlug = computed(() => {
    return league.value?.slug;
  });
  const divisions = computed(() => {
    const teams = league.value?.teams;

    var group = groupBy(teams, "division_name");
    var divisions = Object.keys(group);

    var array = [];

    var i = 0;

    for (i; i < divisions.length; i++) {
      var division = divisions[i];

      array.push({
        division_name: division,
        teams: [],
      });
    }

    var o = 0;

    for (o; o < array.length; o++) {
      division = array[o];

      var filtered_teams = filter(teams, function (team) {
        return team.division_name === division.division_name;
      });

      array[o].teams = filtered_teams;
    }

    // console.log("computedDivisions", array);

    return array;
  });

  const shortcodes = computed(() => {
    return league.value.teams.map((team) => team.shortcode);
  });

  async function getLeague(slug) {
    console.log('getLeagueSlug', slug)
    isLoading.value = true;
    const loading = await showLoading("Please wait...");
    try {
      const results = await api.get("/api/find-league/" + slug);
      league.value = results.data.event;
    } catch (err) {
      showToast("Failed to find League, Please try again!", "danger", 6000);
      // Handle the error state, possibly by setting league.value to a default state or null
      league.value = null; // or {} for an empty object, or a default league structure
    } finally {
      loading.dismiss();
      isLoading.value = false;
    }
  }

  async function reloadEverything(slug) {
    console.log('reloadEverything', slug)
    await Promise.all([getLeague(slug), gameStore.getGames(slug)]);
    const preferenceStore = usePreferenceStore();
    preferenceStore.setAsRecentEvents();
  }

  async function updateLeagueTheme(theme) {
    league.value.theme = theme;
    // Optionally, save the theme preference to the backend
    await api.post('/api/update-league-theme', {
      league_id: league.value.id,
      theme: theme,
    });
    // Handle response and errors as needed
  }

  return {
    league,
    leagueName,
    leagueSlug,
    leagueLogo,
    leagueBackground,
    shortcodes,
    divisions,
    getLeague,
    reloadEverything,
    isLoading,
    updateLeagueTheme
  };
});

<template>
  <ion-card
    class="announcement-card"
    :style="{ width: showDesktopMode ? '80%' : '100%' }"
    v-if="!leagueStore.league.announcements.length"
  >
    <ion-card-header
      ><ion-card-title>No Announcements</ion-card-title></ion-card-header
    >
  </ion-card>
  <ion-card
    class="announcement-card"
    v-for="(announcement, key) in leagueStore.league.announcements"
    :key="key"
  >
    <ion-card-header>
      
      <ion-card-title>{{ announcement.title }}</ion-card-title>
      <ion-card-subtitle>{{
        format(new Date(announcement.created_at), "EEEE, MMM do y h:mm aa")
      }}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <QuillEditor
        contentType="delta"
        :options="options"
        theme=""
        :ref="editor[key]"
        @ready="setContent($event, announcement.description)"
      />
      <ion-button
        size="small"
        v-if="authStore.isConvenor"
        @click="deleteAnnouncement(announcement.id)"
        color="danger">
        Delete
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>
<script setup>
import { defineComponent, ref, onBeforeUnmount } from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/vue";
import { debounce } from "lodash";
import { useLeagueStore } from "@/stores/leagueStore";
import { format } from "date-fns";
import { QuillEditor } from "@vueup/vue-quill";
import { useAuthStore } from "@/stores/authStore";
import { api } from "@/utils/axios";

const authStore = useAuthStore();
defineComponent({
  name: "AnnouncementComponent",
});
const editor = ref([]);
const leagueStore = useLeagueStore();
const showDesktopMode = ref(window.innerWidth >= 960 ? true : false);

const options = {
  readOnly: true,
  enable: false,
};

window.addEventListener("resize", debounce(resizeHandler, 1000));

function resizeHandler() {
  if (window.innerWidth >= 960) {
    showDesktopMode.value = true;
  } else {
    showDesktopMode.value = false;
  }
}
onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeHandler);
});
function setContent(event, content) {
  event.setContents(JSON.parse(content), "api");
}
const deleteAnnouncement = async (id) => {
  try {
    await api.post(`/api/events/${leagueStore.league.id}/announcements/delete`, {
      announcement_id: id
    });
    await leagueStore.reloadEverything(leagueStore.league.slug);
  } catch (error) {
    console.error(error);
  }
};

</script>
<style scoped>
.announcement-card {
  margin: 1rem auto;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: var(--ion-color-primary);
}
</style>

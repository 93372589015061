<template>
  <ion-page>
    <ion-content>
      <sitewide-notice />
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>Diamonds</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="header-text">
        <div class="text-center" style="padding: 20px">
          <h1 style="text-transform: uppercase">
            We're building the <u>most</u> comprehensive list of ball diamond
            information.
          </h1>
          <h3>
            We have
            <vue3-autocounter
              ref="counter"
              :startAmount="500"
              :endAmount="parks_length"
              :duration="3"
              separator=","
              :autoinit="true"
            />
            parks and counting!
          </h3>
          <p>
            You can edit any park's information by viewing the park and
            suggesting edits. No account required. All edits are reviewed by a
            human being, and each successfully approved review puts a ballot
            into our monthly draw.
          </p>
          <p>If you want to add a new park, fill out the form below:</p>
          <ion-button @click="openSuggestParkModal"
            >Submit a Listing</ion-button
          >
          <ion-button fill="outline" @click="openWhyModal"
            >Why are we doing this?</ion-button
          >
        </div>
      </div>
      <div class="header-text" style="margin-top: 2rem">
        <ion-searchbar
          v-model="searchQuery"
          @ionChange="search"
          placeholder="Search for a diamond"
        >
        </ion-searchbar>
        <!-- when searching, it loads a spinner -->
        <ion-spinner v-if="loading"></ion-spinner>
        <!-- results -->
        <ion-row>
          <template v-for="(item, index) in diamondsWithAds" :key="index">
            <template v-if="item.isAd">
              <ion-col size="12">
                <div id="diamondsPage"></div>
              </ion-col>
            </template>
            <template v-else>
              <ion-col size-sm="6" size="12">
                <ion-card>
                  <iframe
                    width="100%"
                    height="250"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    id="gmap_canvas"
                    :src="`https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%${item.address}+()&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
                  ></iframe>

                  <ion-card-header>
                    <ion-card-title>
                      {{ item.name }}
                    </ion-card-title>
                    <ion-card-subtitle>
                      {{ item.address }}
                    </ion-card-subtitle>
                  </ion-card-header>
                  <ion-card-content>
                    <!-- rating -->

                    <ion-button :href="'/ballparks/' + item.slug"
                      >View Park Info</ion-button
                    >
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </template>
          </template>
        </ion-row>
        <!-- infinite scroll -->
        <ion-infinite-scroll threshold="100px" @ionInfinite="loadMore($event)">
          <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more diamonds..."
          >
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>

      <ion-modal
        :is-open="showSuggestParkModal"
        @ionModalWillDismiss="closeSuggestParkModal"
      >
        <ion-header>
          <ion-toolbar>
            <ion-title>Suggest a Park</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeSuggestParkModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <ion-item>
            <ion-label position="stacked">Park Name</ion-label>
            <ion-input v-model="customPark.name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Full Written Address</ion-label>
            <ion-input v-model="customPark.full_written_address"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">City</ion-label>
            <ion-input v-model="customPark.city"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Province</ion-label>
            <ion-input v-model="customPark.province"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Directions</ion-label>
            <ion-input v-model="customPark.directions"></ion-input>
          </ion-item>
          <ion-button v-on:click="submitPark()">Submit Park</ion-button>
        </ion-content>
      </ion-modal>

      <ion-modal :is-open="showWhyModal" @ionModalWillDismiss="closeWhyModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>Why are we doing this?</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeWhyModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content :scrollable="true">
          <div style="padding: 20px">
            <h1>
              Championing Local Sports, Increasing Accessibility, and Nurturing
              the Growth of Baseball
            </h1>
            <p>
              Our endeavor stems from a profound appreciation for baseball, the
              recognition of its place in our cultural fabric, and the
              understanding of its power to build and unite communities. We
              noticed an absence of a central resource where players, fans, and
              community members could find information about local baseball
              fields. With a mission to fill this gap, we're committed to
              creating a unified platform that lists baseball parks and diamonds
              across Canada, America, and beyond.
            </p>
            <p>
              The modern era has seen a refreshing trend towards supporting
              local sports. Authentic and communal, local baseball games provide
              a unique experience that contrasts with mass-produced
              entertainment. We aim to bridge the gap between these local events
              and the audiences seeking them, making it easier to engage with
              the sport at a grassroots level.
            </p>
            <p>
              Our ultimate objective is the growth and prosperity of baseball.
              By enhancing field accessibility and promoting local
              participation, we aspire to infuse new life into the sport. We are
              not merely compiling a directory; we're nurturing a community,
              fostering a platform that inspires the novice, encourages the
              seasoned player, and delights the fan.
            </p>
            <p>
              We envisage a global baseball community, mapping the world's
              fields, uniting fans, and inspiring players. Every game, player,
              and field matters - join us in our journey to bring them all into
              the limelight.
            </p>
            <ion-button @click="closeWhyModal">Close This Popup</ion-button>
            <br /><br /><br />
          </div>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import {
  IonHeader,
  IonPage,
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonContent,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonButton,
  IonCardContent,
  IonRow,
  IonCol,
  IonModal,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/vue";
import { api } from "@/utils/axios";
import { useHead } from "@unhead/vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

import vue3Autocounter from "vue3-autocounter";
// https://cdn.blacktiecollab.com/slopitchcentral/balldiamondmain.png

useHead({
  title: "Diamond Directory - Slo-Pitch Central",
  meta: [
    {
      name: "description",
      content:
        "Explore the most comprehensive directory of ballparks and diamonds on Slo-Pitch Central. Find the perfect field for your next game.",
    },
    {
      name: "keywords",
      content:
        "Slo-Pitch Central, Diamond Directory, Ballparks, Fields, Baseball, Softball, Sports",
    },
    {
      name: "og:title",
      content: "Diamond Directory - Slo-Pitch Central",
    },
    {
      name: "og:description",
      content:
        "Explore the most comprehensive directory of ballparks and diamonds on Slo-Pitch Central. Find the perfect field for your next game.",
    },
    {
      name: "og:image",
      content:
        "https://cdn.blacktiecollab.com/slopitchcentral/balldiamondmain.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com/diamond-directory",
    },
    {
      name: "og:site_name",
      content: "Slo-Pitch Central",
    },
    {
      name: "og:locale",
      content: "en_US",
    },
  ],
});

const searchQuery = ref(""); // Reactive search query
const diamonds = ref([]); // Reactive array to store diamond data
const loading = ref(false); // Reactive variable to show/hide spinner
const currentPage = ref(0); // Current page for infinite scrolling
const moreToLoad = ref(true); // If there's more data to load
const parks_length = ref(1000);
const showSuggestParkModal = ref(false);
const showWhyModal = ref(false);
import { showToast } from "@/utils/useIonicComponents.js";
const customPark = ref({
  name: null,
  full_written_address: null,
  city: null,
  directions: null,
  province: null,
});
const loadMore = async (event) => {
  if (!moreToLoad.value) {
    if (event) event.target.complete();
    return;
  }

  loading.value = true;
  try {
    const response = await api.get(
      `/api/parks?search=${searchQuery.value}&page=${currentPage.value + 1}`
    );
    if (response.data.parks && response.data.parks.length > 0) {
      diamonds.value = [...diamonds.value, ...response.data.parks];
      currentPage.value += 1;
    } else {
      moreToLoad.value = false;
    }
  } catch (error) {
    console.error(error);
  } finally {
    if (event) event.target.complete();
    loading.value = false;
  }
};

const loadParksCount = async () => {
  var response = await api.get("/api/parks-count");
  console.log("response", response);
  parks_length.value = response.data.length;
};

const diamondsWithAds = computed(() => {
  const result = [];
  diamonds.value.forEach((diamond, index) => {
    result.push(diamond);
    // After every 4 cols, insert an ad component placeholder
    if ((index + 1) % 4 === 0) {
      result.push({ isAd: true }); // This is a placeholder for ad component
    }
  });
  console.log(result);
  return result;
});

onMounted(() => {
  loadMore();
  loadParksCount();
}); // Load diamonds on component mount

// const Vue3Autocounter = defineComponent({
//   /* options */
// });

const search = async () => {
  loading.value = true;
  diamonds.value = []; // Reset diamonds array on new search
  currentPage.value = 0; // Reset page count on new search
  moreToLoad.value = true; // Reset loading indicator
  await loadMore();
};

const openSuggestParkModal = () => {
  console.log("open");
  showSuggestParkModal.value = true;
};
const closeSuggestParkModal = () => (showSuggestParkModal.value = false);

const openWhyModal = () => (showWhyModal.value = true);
const closeWhyModal = () => (showWhyModal.value = false);

const submitPark = () => {
  api
    .post("/api/parks/submit-park", {
      park: customPark.value,
    })
    .then((results) => {
      if (results.data.success === true) {
        closeSuggestParkModal();
        showToast("Diamond is submitted!", "success", 6000);
      }
    });
};
</script>

<style scoped>
/* .landing-content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-around;
}
.landing-content-child {
  min-height: 10rem;
  min-width: 35rem;
} */

.header-area {
  width: 100%;
  display: block;
  position: relative !important;
}

/* .background {
  --background: url(https://cdn.blacktiecollab.com/slopitchcentral/images/background2.png) center center no-repeat;
  --background-size: cover;
} */
.header-image {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(125, 56, 240, 0.6) 80%,
    rgba(125, 56, 240, 0.9) 100%
  );
  text-align: center;
}
.header-text {
  max-width: 800px;
  margin: 0 auto;
}

.main-content {
  display: block;
  max-width: 800px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.qa-wrapper {
  padding: 10px 0;
  line-height: 1.5;
}

.question {
  padding-bottom: 5px;
  font-weight: 700;
  color: #7d38f0;
}

ion-card-title {
  font-size: 18px;
}

@media only screen and (max-width: 1250px) {
  /* .landing-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .landing-content-child {
    width: 90%;
    min-width: 20rem;
    max-width: 35rem;
  } */
}

.tally-app {
  background: #121212 !important;
}
</style>

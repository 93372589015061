<template>
  <ion-row class="ion-no-padding">
    <ion-col :size-sm="props.twoColumns ? 6 : 12" size="12">
      <h1
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
        "
      >
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          Search By Event Name
        </span>
      </h1>
      <ion-searchbar
        v-model="searchTerm"
        animated
        mode="md"
        placeholder="Enter Name of Event"
        v-on:keyup.enter="searchForLeague()"
      ></ion-searchbar>

      <div style="max-width: 200px; margin: 0 auto">
        <ion-button expand="block" v-on:click="searchForLeague()"
          >Search</ion-button
        >
      </div>
      <ion-list>
        <ion-item
          button
          v-for="event in events"
          :key="event.id"
          @click="navigateToRecentEvent(event.slug)"
        >
          <ion-label>
            {{ event.name.toUpperCase() }}

            <!-- <span style="font-size: 10px">Recently Opened</span> -->
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-col>
    <ion-col
      :size-sm="props.twoColumns ? 6 : 12"
      size="12"
      v-if="recentEvents.length > 0"
    >
      <h3
        style="
          color: var(--ion-color-dark);
          text-align: center;
          text-transform: uppercase;
        "
      >
        <span
          style="background-color: var(--ion-color-primary); padding: 0 10px"
        >
          Recently Opened
        </span>
      </h3>

      <ion-list>
        <ion-item
          button
          v-for="event in recentEvents"
          :key="event.leagueId"
          @click="navigateToRecentEvent(event.leagueSlug)"
        >
          <ion-label>
            {{ event.leagueName.toUpperCase() }}

            <!-- <span style="font-size: 10px">Recently Opened</span> -->
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
</template>

<script setup>
import { defineComponent, ref, computed, defineProps, defineEmits } from "vue";
import {
  IonSearchbar,
  IonButton,
  IonItem,
  IonList,
  IonRow,
  IonCol,
  IonLabel,
} from "@ionic/vue";
import { api } from "@/utils/axios";
import { showToast, showLoading } from "@/utils/useIonicComponents";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useLeagueStore } from "@/stores/leagueStore";
const route = useRoute();
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
const authStore = useAuthStore();
const events = ref([]);

defineComponent({
  name: "LeagueSearch",
});
// const emit = defineEmits(["eventFound"]);
const searchTerm = ref("");
const props = defineProps(["twoColumns"]);
const emit = defineEmits(["eventFound"]);

async function navigateToRecentEvent(leagueSlug) {
  const results = await api.get("/api/find-league/" + leagueSlug);
  console.log(results);
  const event = results.data.event;
  if (event && event.public !== 1) {
    if (authStore.isLoggedIn) {
      if (
        event.convenor_id !== authStore.user?.id &&
        !event.convenors.some((c) => c.id === authStore.user?.id)
      ) {
        showToast("This event is not public at this moment.", "danger", 5000);
      } else {
        emit("eventFound", {
          slug: event.slug,
          type: event.type,
        });
      }
    } else {
      showToast("This event is not public at this moment.", "danger", 5000);
    }
  } else {
    emit("eventFound", {
      slug: event.slug,
      type: event.type,
    });
  }
}

async function searchForLeague() {
  const loading = await showLoading("Finding League...");
  const result = await api
    .post("/api/event-name", {
      name: searchTerm.value,
    })
    .catch((err) => {
      console.log(err);
      showToast("Failed to find League, Please try again!", "danger", 6000);
    });

  console.log("result", result.data);
  events.value = result.data;
  loading.dismiss();
  //   if (!result.data) {
  //     showToast(
  //       "Failed to find League, Please recheck shortcode again!",
  //       "danger",
  //       6000
  //     );
  //     return;
  //   }
  //   if (result.data.public !== 1) {
  //     if (authStore.isLoggedIn) {
  //       if (result.data.convenor_id !== authStore.user.id) {
  //         const res = await api.post("/api/grab-event-admins", {
  //           eventId: result.data.id,
  //         });
  //         if (!res.data.convenors.some((c) => c.id === authStore.user?.id)) {
  //           showToast("This event is not public at this moment.", "danger", 5000);
  //           return;
  //         }
  //       }
  //     } else {
  //       showToast("This event is not public at this moment.", "danger", 5000);
  //       return;
  //     }
  //   }
  //   emit("eventFound", {
  //     slug: result.data.slug,
  //     name: result.data.name,
  //     type: result.data.type,
  //   });
}

const recentEvents = computed(() => {
  if (route.fullPath !== "/") {
    return preferenceStore.userRecentEvents
      .filter((event) => event.leagueSlug !== leagueStore.league?.slug)
      .slice(0, 3);
  }
  return preferenceStore.userRecentEvents.slice(0, 3);
});
</script>

import { defineStore } from "pinia";
import { ref } from "vue";
import { format, parseISO, parse, add, set } from "date-fns";
import { api } from "@/utils/axios";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useReportGameStore } from "@/stores/reportGameStore";

import { showToast, showLoading } from "@/utils/useIonicComponents";
import { parseDate, parseTime } from "@/utils/useDateTimeFunctions";
import { computed } from "vue";

export const useNewGameStore = defineStore("newGame", () => {
  const games = ref([]);
  const gameStore = useGameStore();
  const preferenceStore = usePreferenceStore();

  const tempGameId = computed(() => {
    // this keeps track of and generates tempGameId for addNewGames
    const gameIds = gameStore.games
      .map((item) => item.event_specific_game_id)
      .filter((game_id) => game_id !== undefined);
    const maxGameId = gameIds.length ? Math.max(...gameIds) : undefined;
    if (games.value.length < 1) {
      if (maxGameId) {
        return maxGameId + 1;
      }
      return 1;
    } else {
      const validGames = games.value.filter(
        // remove part games
        (game) => typeof game.tempGameId === "number" && !isNaN(game.tempGameId)
      );

      if (validGames.length < 1) {
        if (maxGameId) {
          return maxGameId + 1;
        }
        return 1;
      }
      const max = validGames.reduce((acc, current) => {
        return acc.tempGameId > current.tempGameId ? acc : current;
      });
      return max.tempGameId + 1;
    }
  });

  function addNewGame(gameindex = -1) {
    const leagueStore = useLeagueStore();
    console.log(tempGameId.value);
    let date = new Date();
    if (gameStore.currentShownDate !== "all" && gameStore.currentShownDate) {
      date = new Date(gameStore.currentShownDate);
    } else {
      date = new Date();
    }
    if (gameindex < 0) {
      games.value.push({
        start_date: format(date, "yyyy-MM-dd"),
        start_time: format(
          set(new Date(), { minutes: 0, seconds: 0 }),
          "HH:mm:ss"
        ),
        end_time: format(
          add(set(new Date(), { minutes: 0, seconds: 0 }), {
            minutes: leagueStore.league.default_game_minutes || 75,
          }),
          "HH:mm:ss"
        ),
        park_name: null,
        diamond_signifier: null,
        division_id: null,
        select_away_team: null,
        select_home_team: null,
        mode: "Season",
        best_of: 1,
        tempGameId: tempGameId.value,
        if_necessary: 0,
      });
    } else {
      // to duplicate a game
      games.value.push(Object.assign({}, games.value[gameindex]));
      games.value[games.value.length - 1].tempGameId = tempGameId.value;
    }
  }
  function removeGame(index) {
    const deleteGame = games.value[index];
    games.value.splice(index, 1);
    games.value = games.value.filter(
      (game) => game.part_of !== deleteGame.tempGameId
    );
    if (games.value.length < 1) {
      addNewGame();
    }
    preferenceStore.setUserNewGames();
  }
  function dateTimeChanged(payload, type, index) {
    // to format date and time for date-time input in addnewgame
    if (type === "date") {
      if (payload.detail.value !== null) {
        const formattedString = format(
          parseISO(payload.detail.value),
          "yyyy-MM-dd"
        );
        games.value[index].start_date = formattedString;
      } else {
        games.value[index].start_date = null;
      }
    }
    if (type === "start_time") {
      if (payload.detail.value !== null) {
        const formattedString = format(
          parseISO(payload.detail.value),
          "HH:mm:ss"
        );
        games.value[index].start_time = formattedString;
        const leagueStore = useLeagueStore();
        games.value[index].end_time = format(
          add(set(parseISO(payload.detail.value), { minutes: 0, seconds: 0 }), {
            minutes: leagueStore.league.default_game_minutes,
          }),
          "HH:mm:ss"
        );
      } else {
        games.value[index].start_time = null;
        games.value[index].end_time = null;
      }
    }
    if (type === "end_time") {
      const formattedString = format(
        parseISO(payload.detail.value),
        "HH:mm:ss"
      );
      games.value[index].end_time = formattedString;
    }
  }

  function importGamesFromGenerator(genGames) {
    console.log("import from gen");
    for (const game of genGames) {
      const newGame = {
        start_date: game.start_date,
        start_time: game.start_time,
        end_time: game.end_time,
        park_name: game.park_name,
        diamond_signifier: game.diamond_signifier,
        division_id: game.division_id,
        select_away_team: game.select_away_team,
        select_home_team: game.select_home_team,
        mode: "Season",
        best_of: 1,
        tempGameId: tempGameId.value,
        if_necessary: 0,
      };
      newGame.start_date = parseDate(newGame.start_date);
      newGame.start_time = parseTime(newGame.start_time);
      newGame.end_time = parseTime(newGame.end_time);

      if (!game["end_time"]) {
        newGame.end_time = add(
          parse(newGame.start_time, "HH:mm:ss", new Date()),
          { minutes: 90 }
        );
        newGame.end_time = format(newGame.end_time, "HH:mm:ss");
      }

      games.value.push(newGame);
    }
    preferenceStore.setUserNewGames();
  }

  function importGames(data, association) {
    //called from uploadcsvstore
    // games.value.length = 0;
    try {
      for (const game of data) {
        const newGame = {
          start_date: format(new Date(), "yyyy-MM-dd"),
          start_time: format(
            set(new Date(), { minutes: 0, seconds: 0 }),
            "HH:mm:ss"
          ),
          end_time: format(
            add(set(new Date(), { minutes: 0, seconds: 0 }), { minutes: 90 }),
            "HH:mm:ss"
          ),
          park_name: null,
          diamond_signifier: null,
          division_id: null,
          select_away_team: null,
          select_home_team: null,
          mode: "Season",
          best_of: 1,
          tempGameId: tempGameId.value,
          if_necessary: 0,
        };
        for (const [key, value] of Object.entries(association)) {
          newGame[key] = game[value];
        }
        newGame.start_date = parseDate(newGame.start_date);
        newGame.start_time = parseTime(newGame.start_time);
        newGame.end_time = parseTime(newGame.end_time);

        if (!game[association["end_time"]]) {
          newGame.end_time = add(
            parse(newGame.start_time, "HH:mm:ss", new Date()),
            { minutes: 90 }
          );
          newGame.end_time = format(newGame.end_time, "HH:mm:ss");
        }

        games.value.push(newGame);
      }
      preferenceStore.setUserNewGames();
    } catch (err) {
      games.value.length = 0;
      throw err;
    }
  }

  async function saveGames() {
    const loading = await showLoading("Adding New Games. Please Wait...");
    const leagueStore = useLeagueStore();
    try {
      const response = await api.post(`/api/save-new-game`, {
        event_id: leagueStore.league.id,
        game_data: games.value,
      });
      loading.dismiss();
      showToast("New Games Added Successfully!", "success");

      const tempIdtoDbIdMap = response.data.tempIdToDbIdMap;
      const insertedGames = games.value.map((game) => {
        if (tempIdtoDbIdMap[game.tempGameId]) {
          return { ...game, id: tempIdtoDbIdMap[game.tempGameId] };
        } else {
          return game;
        }
      });
      const reportStore = useReportGameStore();
      const existingReportGames = reportStore.games.value;

      reportStore.games = insertedGames.filter(
        (obj) =>
          Object.prototype.hasOwnProperty.call(obj, "away_team_score") &&
          Object.prototype.hasOwnProperty.call(obj, "home_team_score")
      );
      if (reportStore.games.length > 0) {
        await reportStore.sendReportRequest();
        reportStore.games.value = existingReportGames;
      }
      await leagueStore.reloadEverything(leagueStore.league.slug);

      return {
        success: true,
      };
    } catch (err) {
      console.log(err);
      showToast("Adding New Games Failed", "danger");
      loading.dismiss();
      return { error: true };
    }
  }

  return {
    games,
    tempGameId,
    addNewGame,
    removeGame,
    dateTimeChanged,
    saveGames,
    importGames,
    importGamesFromGenerator,
  };
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import axiosSetup from "@/utils/interceptors";
import Adsense from "vue-google-adsense/dist/Adsense.min.js";
import ScriptX from "vue-scriptx";

import { IonicVue } from "@ionic/vue";
import { createHead } from "@unhead/vue";
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import './registerServiceWorker'

const script = document.createElement('script');
script.src = '//cdn.intergient.com/1025306/75041/ramp.js';
script.async = true;
document.head.appendChild(script);

const pinia = createPinia();
const head = createHead();
const app = createApp(App)
  .use(IonicVue, { mode: "ios" })
  .use(router)
  .use(pinia)
  .use(head)
  .use(ScriptX)
  .use(Adsense);

axiosSetup();
router.isReady().then(() => {
  app.mount("#app");
});

<template>
  <ion-grid>
    <ion-row v-for="games in gameStore.dateWiseFilteredGames" :key="games">
      <div class="date-card" style="width: 100%">
        <h1 style="color: var(--ion-color-dark)">
          <span
            style="background-color: var(--ion-color-primary); padding: 0 10px"
          >
            {{
              games.date !== "null"
                ? format(
                    new Date(games.date.replace(/-/g, "/")),
                    "EEEE, MMM do"
                  )
                : "TBA"
            }}
          </span>
        </h1>
      </div>
      <ion-col
        v-for="game in games.games"
        :key="game.id"
        size="12"
        size-md="6"
        size-lg="6"
        size-xl="4"
      >
        <game-card :game="game" />
        <ion-card style="min-width: 20rem; margin: 0.5rem 0.5rem"> </ion-card>
      </ion-col>
      <div class="in-content"></div>
    </ion-row>
    <no-game-card v-if="gameStore.dateWiseFilteredGames.length < 1" />
  </ion-grid>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, IonCard } from "@ionic/vue";
import { format } from "date-fns";

import { useGameStore } from "@/stores/gameStore";
import { useReportGameStore } from "@/stores/reportGameStore";
import GameCard from "./GameCard.vue";
import NoGameCard from "@/components/schedule/NoGameCard.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "ScheduledGamesAll",
});
const gameStore = useGameStore();
const reportGames = useReportGameStore();
reportGames.games = []; // to reset edited score, when component is re rendered.
</script>

<style scoped>
ion-grid {
  margin-bottom: 40px;
}

.date-card {
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 15px;
}
.date-card h1 {
  text-transform: uppercase;
  font-weight: 700;
  @media all and (max-width: 600px) {
    font-size: 18px;
  }
}
</style>

<template>
  <ion-button expand="block" @click="openNewEventModal"
    >Create New League/Tournament</ion-button
  >
</template>
<script setup>
import { defineComponent, defineEmits } from "vue";
import { IonButton } from "@ionic/vue";
import NewEventModal from "@/components/event/NewEventModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";

defineComponent({
  name: "NewEventComponent",
});
const emit = defineEmits(["new-event"]);

async function openNewEventModal() {
  const response = await openModal(NewEventModal, "newEventModal");
  if (response === true) {
    emit("new-event");
  }
}
</script>

<template>
  <page-wrapper>
    <sitewide-notice />
    <ion-header>
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title>Blog Posts</ion-title>
      </ion-toolbar>
    </ion-header>
    <card-wrapper
      cardTitle="Our Blog"
      id="featured"
      cardSubtitle="Interesting information on a variety of Slo-Pitch topics."
    >
      <ion-row class="ion-no-padding">
        <template v-for="(post, index) in posts" :key="post.id">
          <ion-col size-sm="6" size="12">
            <ion-card class="ion-no-padding ion-no-margin">
              <img
                :src="post.jetpack_featured_media_url"
                :alt="formattedTitle(post?.title?.rendered) + ' Title Image'"
              />
              <ion-card-header>
                <ion-card-title style="text-transform: none">
                  {{ formattedTitle(post?.title?.rendered) }}
                </ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <div class="text-excerpt" v-html="post.excerpt.rendered"></div>
                <br />
                <ion-button
                  size="small"
                  expand="full"
                  :router-link="`/blog/${post.slug}`"
                >
                  Read Now
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <template v-if="(index + 1) % 4 === 0">
            <ion-col :key="'ad-' + index" size="12">
              <div id="postsPage"></div>
            </ion-col>
          </template>
        </template>
      </ion-row>
    </card-wrapper>
    <footer-component></footer-component>
  </page-wrapper>
</template>

<script setup>
import { defineComponent, ref, onMounted } from "vue";
import CardWrapper from "@/components/layouts/CardWrapper.vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";
import FooterComponent from "@/components/landing/FooterComponent.vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import axios from "axios";
import {
  IonRow,
  IonCol,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonButton,
  onIonViewWillEnter,
} from "@ionic/vue";
import he from "he";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "PostsPage",
});

const posts = ref([]);

onIonViewWillEnter(async () => {
  await grabBlogPosts();
});

onMounted(async () => {
  await grabBlogPosts();
});

const formattedTitle = (title) => {
  return he.decode(title);
};

async function grabBlogPosts() {
  axios
    .get("https://wii.dnw.mybluehost.me/wp-json/wp/v2/posts")
    .then((results) => {
      console.log("results", results);
      posts.value = results.data;
    });
}
</script>

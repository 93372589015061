<template>
  <page-wrapper>
    <sitewide-notice></sitewide-notice>
    <ion-header>
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start">
          <ion-menu-button color="dark"></ion-menu-button>
        </ion-buttons>
        <ion-title v-if="post">{{
          formattedTitle(post.title.rendered)
        }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <span v-if="post">
      <div
        class="header-image"
        :style="{
          background: `url(${post.jetpack_featured_media_url}) center center no-repeat`,
          backgroundSize: 'cover',
          width: '100%',
          height: '500px',
        }"
      ></div>
      <div style="width: 100%; height: 280px; margin-top: 1rem">
        <!-- <playwire-ads
          type="leaderboard_atf"
          selectorId="pwLeaderboardAtf"
          className="leaderboardatf"
        /> -->
        <div id="pwLeaderboardAtf"></div>
      </div>
      <div class="wrapper">
        <div class="blog-post-header">
          <h1>{{ formattedTitle(post.title.rendered) }}</h1>
          <div class="blog-post-date">
            Written on: {{ moment(post.date).format("MMMM Do, YYYY") }}
          </div>
        </div>

        <div class="blog-post-content" v-html="post.content.rendered"></div>
      </div>
    </span>
    <div style="width: 100%; height: 280px; margin-top: 2rem">
      <div id="pwLeaderboardBtf"></div>
      <!-- <playwire-ads
        type="'leaderboard_atf'"
        selectorId="'pwLeaderboardBtf'"
        className="'leaderboardbtf'"
      /> -->
    </div>
  </page-wrapper>
</template>

<script setup>
import he from "he";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";
// import PlaywireAds from "@/components/ads/PlaywireAds.vue";
import PageWrapper from "@/components/layouts/PageWrapper.vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";

import {
  onIonViewWillEnter,
  IonHeader,
  IonTitle,
  IonMenuButton,
  IonButtons,
  IonToolbar,
} from "@ionic/vue";
const route = useRoute();

import { useHead } from "@unhead/vue";

const isReady = ref(false);
setTimeout(() => {
  isReady.value = true;
}, 1000);

import moment from "moment";

const post = ref(null);

const getBlogPost = () => {
  var slug = route.params.slug;

  axios
    .get(`https://wii.dnw.mybluehost.me/wp-json/wp/v2/posts?slug=${slug}`)
    .then((results) => {
      console.log("results", results);

      post.value = results.data[0];
      useHead({
        title: post.value.yoast_head_json.title,
        meta: [
          {
            name: "description",
            content: post.value.yoast_head_json.description,
          },
          {
            name: "og:title",
            content: post.value.yoast_head_json.title,
          },
          {
            name: "og:description",
            content: post.value.yoast_head_json.description,
          },
          {
            name: "og:image",
            content: post.value.jetpack_featured_media_url,
          },
          {
            name: "og:type",
            content: post.value.yoast_head_json.og_type,
          },
          {
            name: "og:url",
            content: `https://slopitchcentral.com/blog/${slug}`,
          },
          {
            name: "og:author",
            content: post.value.yoast_head_json.author,
          },
        ],
      });
    });
};

onMounted(() => {
  getBlogPost();
});

onIonViewWillEnter(() => {
  getBlogPost();
});

const formattedTitle = (title) => {
  return he.decode(title);
};
</script>

<style scoped>
.wrapper {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.header-area {
  width: 100%;
  display: block;
  position: relative !important;
}

.text-center {
  text-align: center;
}

h1 {
  font-weight: 700;
}

.header-image {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(106, 100, 255, 0.4) 80%,
    rgba(106, 100, 255, 0.9) 100%
  );
  text-align: center;
}
.header-text {
  max-width: 800px;
  margin: 0 auto;
}

.padding {
  padding: 10px;
  text-align: center;
}

.row-title {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  font-size: 30px;
}

.row-description {
  font-size: 18px;
  padding: 10px;
}

.qa-wrapper {
  padding: 10px 0;
  line-height: 1.5;
  font-size: 18px;
}

.question {
  padding-bottom: 5px;
  font-weight: 700;
  color: #6a64ff;
}

ion-card {
  box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.3);
  background: #111;
}
ion-card-header {
  background: #111;
  margin-bottom: 10px;
  border-top: 3px solid #6a64ff;
}
ion-card-title {
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  --color: var(--ion-color-dark);
}

#container {
  max-width: 800px;
  margin: 0 auto;
}

ion-header {
  background: #6a64ff;
  font-weight: 700;
}
ion-segment-button {
  font-weight: 700;
}

.blog-post-header {
  text-align: center;
}

.blog-post-date {
  text-align: center;
  color: #aaa;
}
</style>

let isInitialLoad = true;

// Define the units array here
const pwUnits = [
  { type: "corner_ad_video" },
  { type: "in_content" },
  { type: "sticky_sidebar" },
  { type: "bottom_rail" },
  { type: "left_rail" },
  { type: "right_rail" },
];

export const rampOperations = {
  reinitialize: (to, from) => {
    console.log('reinitialize', { to, from });
    if (window.ramp && window.ramp.que) {
      window.ramp.que.push(() => {
        // Only destroy units if it's not the initial load and the path has changed
        if (!isInitialLoad && to !== from) {
          window.ramp.destroyUnits('all');
        }

        // Set the path
        window.ramp.setPath(to === '/' ? '/' : 'ROS');

        // Add units using the pwUnits array defined above
        window.ramp.addUnits(pwUnits)
          .then(() => {
            // Display units
            window.ramp.displayUnits();
          })
          .catch((e) => {
            console.error('RAMP error:', e);
            window.ramp.displayUnits();
          });

        // Set isInitialLoad to false after the first run
        isInitialLoad = false;
      });
    }
  }
};
<template>
  <ion-grid>
    <ion-row>
      <template
        v-for="(game, index) in gameStore.filteredGamesCurrentDate"
        :key="game.id"
      >
        <ion-col
          v-if="isPlatform('mobile') && index > 0 && index % 6 === 0"
          size="12"
        >
          <div class="in-content"></div>
        </ion-col>
        <ion-col
          size="12"
          v-if="isPlatform('desktop') && index > 0 && index % 9 === 0"
        >
          <div class="in-content"></div>
        </ion-col>
        <ion-col size="12" size-md="6" size-xl="4">
          <game-card :game="game" />
        </ion-col>
      </template>
      <ion-col v-if="gameStore.filteredGamesCurrentDate.length < 1">
        <no-game-card />
      </ion-col>
      <ion-col
        size="12"
        v-if="
          isPlatform('mobile') &&
          gameStore.filteredGamesCurrentDate.length % 6 > 3
        "
      >
        <div class="in-content"></div>
      </ion-col>
      <ion-col size="12" v-if="isPlatform('desktop')">
        <div
          id="inlineRectAtf"
          v-if="
            gameStore.filteredGamesCurrentDate.length % 9 > 6 ||
            gameStore.filteredGamesCurrentDate.length < 9
          "
        ></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script setup>
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol, isPlatform } from "@ionic/vue";

import { useGameStore } from "@/stores/gameStore";
import GameCard from "./GameCard.vue";
import NoGameCard from "@/components/schedule/NoGameCard.vue";
// import NewAdComponent from "@/components/ads/NewAdComponent.vue";

defineComponent({
  name: "ScheduledGamesGrid",
});
const gameStore = useGameStore();
</script>

<template>
  <ion-list class="list">
    <ion-item>
      <ion-label>Show Reported Games</ion-label>
      <ion-toggle
        :checked="gameStore.showReportedGames"
        @ion-change="filtersChanged($event, 'showReportedGames')"
        >Show Reported Games</ion-toggle
      >
      <!-- <ion-select
        placeholder="Pick Option"
        :value="gameStore.showReportedGames"
        @ion-change="filtersChanged($event, 'showReportedGames')"
      >
        <ion-select-option :value="true">Yes</ion-select-option>
        <ion-select-option :value="false">No</ion-select-option>
      </ion-select> -->
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Division</ion-label>
      <v-select
        style="min-width: 6.2rem"
        placeholder="Select Division"
        :options="[
          { label: 'All Divisions', value: null },
          ...gameStore.filterOptions.division_id.map((division) => ({
            label: division,
            value: division,
          })),
        ]"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        :modelValue="{
          label: gameStore.gameFilters.division_id
            ? gameStore.gameFilters.division_id
            : 'All Divisions',
          value: gameStore.gameFilters.division_id
            ? gameStore.gameFilters.division_id
            : null,
        }"
        @option:selected="filtersChanged($event, 'division_id')"
      >
        <template #open-indicator="{ attributes }">
          <ion-icon
            :icon="caretDownOutline"
            style="font-size: 0.8rem"
            v-bind="attributes"
          ></ion-icon>
        </template>
      </v-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Location</ion-label>
      <ion-select
        placeholder="Select Location"
        :value="gameStore.gameFilters.park_name"
        @ion-change="filtersChanged($event, 'location')"
      >
        <ion-select-option :value="null">All Locations</ion-select-option>
        <ion-select-option
          v-for="location in gameStore.filterOptions.park_name"
          :key="location"
          :value="location"
          >{{ location }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Diamond</ion-label>
      <ion-select
        placeholder="Select Diamond"
        :value="gameStore.gameFilters.diamond_signifier"
        @ion-change="filtersChanged($event, 'diamond')"
      >
        <ion-select-option :value="null">All Diamonds</ion-select-option>
        <ion-select-option
          v-for="diamond in gameStore.filterOptions.diamond_signifier"
          :key="diamond"
          :value="diamond"
          >{{ diamond }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Teams</ion-label>
      <team-filter />
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Timeslot</ion-label>
      <ion-select
        placeholder="Select Time"
        v-model="gameStore.gameFilters.start_time"
        @ion-change="filtersChanged($event, 'timeslot')"
      >
        <ion-select-option :value="null">All Timeslots</ion-select-option>
        <ion-select-option
          v-for="timeslot in gameStore.filterOptions.start_time"
          :key="timeslot"
          :value="timeslot"
          >{{
            timeslot !== "TBA"
              ? format(
                  set(new Date(), {
                    hours: timeslot.split(":")[0],
                    minutes: timeslot.split(":")[1],
                  }),
                  "h:mm aa"
                )
              : "TBA"
          }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Month</ion-label>
      <ion-select
        placeholder="Select Month"
        v-model="gameStore.gameFilters.month"
        @ionChange="filtersChanged($event, 'month')"
      >
        <ion-select-option :value="null">All Months</ion-select-option>
        <ion-select-option
          v-for="month in gameStore.filterOptions.month"
          :key="month"
          :value="month"
          >{{ month }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Day of Week</ion-label>
      <ion-select
        placeholder="Select Day"
        v-model="gameStore.gameFilters.day"
        @ionChange="filtersChanged($event, 'day')"
      >
        <ion-select-option :value="null">All Days</ion-select-option>
        <ion-select-option
          v-for="day in gameStore.filterOptions.day"
          :key="day"
          :value="day"
          >{{ day }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </ion-list>
</template>
<script setup>
// Some of the filter reactive code is in App.vue && updateFiltersOnLoad.js
// When changing stuff here, change in those files appropriately

import { defineComponent } from "vue";
import {
  IonSelect,
  IonSelectOption,
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  IonToggle,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { format, set } from "date-fns";
import { useRouter, useRoute } from "vue-router";
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import TeamFilter from "@/components/schedule/filters/TeamFilter.vue";

defineComponent({
  name: "FiltersComponent",
});
const leagueStore = useLeagueStore();
const gameStore = useGameStore();
const preferenceStore = usePreferenceStore();
const router = useRouter();
const route = useRoute();

function filtersChanged(ev, filterName) {
  //to stop update when leagues switch
  if (route.params.leagueSlug === leagueStore.league.slug) {
    if (filterName === "showReportedGames") {
      const changedValue = ev.detail.checked;
      gameStore.showReportedGames = changedValue;

      router.replace({
        query: {
          ...route.query,
          showReportedGames: gameStore.showReportedGames,
        },
      });
    }

    if (filterName === "division_id") {
      gameStore.gameFilters[filterName] = ev.value;
      router.replace({
        query: {
          ...route.query,
          division_id: gameStore.gameFilters.division_id,
        },
      });
    }

    if (filterName === "location") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.park_name = changedValue;
      } else {
        gameStore.gameFilters.park_name = null;
      }

      router.replace({
        query: {
          ...route.query,
          park_name: changedValue,
        },
      });
    }
    if (filterName === "diamond") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.diamond_signifier = changedValue;
      } else {
        gameStore.gameFilters.diamond_signifier = null;
      }

      router.replace({
        query: {
          ...route.query,
          diamond_signifier: changedValue,
        },
      });
    }

    if (filterName === "timeslot") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.gameFilters.start_time = changedValue;
      } else {
        gameStore.gameFilters.start_time = null;
      }

      router.replace({
        query: {
          ...route.query,
          timeslot: changedValue,
        },
      });
    }

    if (filterName === "month") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.month = changedValue;
      } else {
        gameStore.gameFilters.month = null;
      }

      router.replace({
        query: {
          ...route.query,
          month: changedValue,
        },
      });
    }
    if (filterName === "day") {
      const changedValue = ev.detail.value;
      if (changedValue) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.day = changedValue;
      } else {
        gameStore.gameFilters.day = null;
      }

      router.replace({
        query: {
          ...route.query,
          day: changedValue,
        },
      });
    }

    preferenceStore.setFilters({
      ...gameStore.gameFilters,
      showReportedGames: gameStore.showReportedGames,
    });
  }
}

function withPopper(dropdownList, component) {
  dropdownList.style.width = "20rem";
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
</script>
<style scoped>
.list ion-item {
  --padding-start: 0px;
}

.v-select {
  --vs-controls-color: var(--ion-color-step-300);
  --vs-border-width: 0;

  --vs-dropdown-bg: var(--ion-background-color);
  --vs-dropdown-option-color: var(--ion-text-color);

  --vs-selected-bg: var(--ion-color-light);
  --vs-selected-color: var(--ion-text-color);

  --vs-search-input-color: var(--ion-color-step-300);

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
  --vs-dropdown-min-width: 1rem;
  --vs-dropdown-option--deselect-color: red;

  --vs-disabled-bg: var(--ion-color-light);
  --vs-disabled-color: var(--ion-color-light);
  --vs-disabled-controls-color: var(--ion-color-light);
}
</style>

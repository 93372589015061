<template>
  <div style="text-align: center;">
    <ion-button
      :fill="selectedDate === 'today' ? 'solid' : 'outline'"
      @click="selectDate('today')"
    >Today</ion-button>
    <ion-button
      :fill="selectedDate === 'tomorrow' ? 'solid' : 'outline'"
      @click="selectDate('tomorrow')"
    >Tomorrow</ion-button>
    <ion-button
      :fill="selectedDate === 'weekend' ? 'solid' : 'outline'"
      @click="selectDate('weekend')"
    >This Weekend</ion-button>
  </div>
   <ion-row class="ion-no-padding">
    <ion-col size-sm="6" size="12" v-for="event in featuredEvents" :key="event.id">
      <ion-card class="ion-no-margin" :color="event.color || 'primary'">
        <ion-card-header>
          <ion-card-title>{{ event.name }}</ion-card-title>
          <ion-card-subtitle>{{ event.city }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-button
            size="small"
            color="light"
            expand="full"
            :router-link="`/${event.type}/${event.slug}/schedule`"
          >
            Go Now
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  
</template>
<script setup>
import { defineComponent, onMounted, ref } from "vue";
import { api } from "@/utils/axios";
import {
  IonButton,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  onIonViewWillEnter
} from "@ionic/vue";

const featuredEvents = ref([]);
const selectedDate = ref('today'); // Default to 'today'

const grabFeaturedEvents = async () => {
  try {
    const response = await api.get(`/api/featured-events?date=${selectedDate.value}`);
    featuredEvents.value = response.data; // Store the data in the reactive reference
    console.log('grabFeaturedEvents', response.data);
  } catch (error) {
    console.error(error);
  }
};

const selectDate = (date) => {
  selectedDate.value = date;
  grabFeaturedEvents(); // Refresh events when date changes
};

// Call grabFeaturedEvents when the component is mounted
onMounted(grabFeaturedEvents);

// If using Ionic, you can also listen for the ionViewWillEnter event
onIonViewWillEnter(grabFeaturedEvents);

defineComponent({
  name: "FeaturedEvents",
});
</script>
<style scoped>
ion-card-title {
  font-size: 18px;
}
</style>
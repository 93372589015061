<template>
    <div class="bracket-main" v-if="brackets">
        <div style="padding: 5px; text-align: center; background: #333;">
            <b>Beta Feature. Contact us if it does not work as expected</b>
        </div>
        <ion-segment @ionChange="selectBracket($event)" value="selectedBracket">
            <ion-segment-button
                v-for="(bracket, key) in brackets_listed"
                :key="key"
                :value="bracket"
            >
                <ion-label>{{ bracket }}</ion-label>
            </ion-segment-button>
        </ion-segment>
        <!-- <div v-for="(bracket, key) of brackets_listed" :key="key" class="bracket-wrapper"> -->
            <!-- <div class="bracket-title">{{ bracket }}</div> -->
            <button class="zoom-in" @click="zoomIn">Zoom In</button>
            <button class="zoom-out" @click="zoomOut">Zoom Out</button>
            <div 
                class="bracket-viewer" 
                v-if="selectedBracket" 
                ref="bracketViewer"
                @mousedown="onMouseDown"
                @mouseleave="onMouseLeave"
                @mouseup="onMouseUp"
                @mousemove="onMouseMove"
                @touchstart="onTouchStart"
                @touchmove="onTouchMove"
                @touchend="onTouchEnd">
                <div class="bracket-padding" :style="{ transform: 'scale(' + scale + ')' }">
                    <BracketGame 
                        :game="brackets[selectedBracket]"
                    />

                    
                </div>

                
            </div>
        <!-- </div>         -->
    </div>
  </template>
  
  <script>
  import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
  import BracketGame from '@/components/brackets/BracketGame';
  import { api } from "@/utils/axios";
  export default {
    name: 'BracketViewer',
    components: {
        BracketGame,
      
        IonSegment,
        IonSegmentButton,
        IonLabel,
    },
    data() {
        return {
            brackets: null,
            brackets_listed: [],
            selectedBracket: null,
            scale: 0.8,
            isPanning: false,
            startX: 0,
            startY: 0,
            scrollLeft: 0,
            scrollTop: 0
        };
    },
    mounted: function() {
        this.getBracketInfo();
    },
    methods: {
        getBracketInfo: async function() {
            const response = await api.get(`/brackets/test-theory/${ this.$route.params.leagueSlug}`);
            if (response.data) {
                this.brackets_listed = Object.keys(response.data.brackets)
                this.brackets = response.data.brackets

                const keys = Object.keys(this.brackets)

                this.selectedBracket = keys[0]
            }
        },
        selectBracket: function(event) {
            this.selectedBracket = event.detail.value
        },
        zoomIn() {
            this.scale += 0.1; // Increase scale by 0.1
        },
        zoomOut() {
            this.scale = Math.max(0.1, this.scale - 0.1); // Decrease scale by 0.1, but not below 0.1
        },
        onMouseDown(e) {
        // Check if the event target is not an input, button, or other form elements
            if (!['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON', 'A'].includes(e.target.tagName)) {
                this.isPanning = true;
                this.startX = e.pageX - this.$refs.bracketViewer.offsetLeft;
                this.startY = e.pageY - this.$refs.bracketViewer.offsetTop;
                this.scrollLeft = this.$refs.bracketViewer.scrollLeft;
                this.scrollTop = this.$refs.bracketViewer.scrollTop;
                e.preventDefault(); // Prevent text selection and other default actions
            }
        },
        onMouseLeave() {
            this.isPanning = false;
        },
        onMouseUp() {
            this.isPanning = false;
        },
        onMouseMove(e) {
            if (!this.isPanning) return;
            const x = e.pageX - this.$refs.bracketViewer.offsetLeft;
            const y = e.pageY - this.$refs.bracketViewer.offsetTop;
            const walkX = (x - this.startX);
            const walkY = (y - this.startY);
            this.$refs.bracketViewer.scrollLeft = this.scrollLeft - walkX;
            this.$refs.bracketViewer.scrollTop = this.scrollTop - walkY;
        },
        onTouchStart(e) {
            this.isPanning = true;
            const touch = e.touches[0];
            this.startX = touch.clientX - this.$refs.bracketViewer.offsetLeft;
            this.startY = touch.clientY - this.$refs.bracketViewer.offsetTop;
            this.scrollLeft = this.$refs.bracketViewer.scrollLeft;
            this.scrollTop = this.$refs.bracketViewer.scrollTop;
        },
        onTouchMove(e) {
            if (!this.isPanning) return;
            const touch = e.touches[0];
            const x = touch.clientX - this.$refs.bracketViewer.offsetLeft;
            const y = touch.clientY - this.$refs.bracketViewer.offsetTop;
            const walkX = (x - this.startX);
            const walkY = (y - this.startY);
            this.$refs.bracketViewer.scrollLeft = this.scrollLeft - walkX;
            this.$refs.bracketViewer.scrollTop = this.scrollTop - walkY;
        },
        onTouchEnd() {
            this.isPanning = false;
        }
    }
  };
  </script>
  
  <style scoped lang="scss">

  .bracket-main {
    position: relative;
  }

    .bracket-title {
        background-color: var(--ion-color-primary);
        padding: 0px 10px;
        font-size: 26px;
        font-weight: 700;
    }

    .bracket-viewer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        width: 100%;
        position: relative;
        height: calc(100vh - 135px);
        // margin-bottom: 70px;
        
        overflow-y: auto;
        overflow-x: auto; /* Allow horizontal scrolling */

         // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
        display: none;
        }

        // Hide scrollbar for IE, Edge and Firefox
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  
    }


  .bracket-padding {
    /* If you want the bracket-padding to be centered horizontally inside bracket-viewer */
    display: flex;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
    // width: 100%;
    padding: 20px;
    transform-origin: top;
  }


    @media (max-width: 600px) {
        .bracket-viewer {
            font-size: smaller; /* Smaller text on very small screens */
        }
    }

    .bracket-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        // background: #444;
    }
        
    .bracket-padding {
        padding: 20px;
    }

    .zoom-in, .zoom-out {
        position: absolute;
    }

    .zoom-in {
        bottom: 30px;
        right: 90px;
        padding: 10px;
        border-radius: 5px;
        z-index: 100;
    }

    .zoom-out {
        bottom: 30px;
        right: 20px;
        padding: 10px;
        border-radius: 5px;
        z-index: 100;
    }

    
  </style>
  
<template>
  <ion-page>
    <ion-content>
      <sitewide-notice></sitewide-notice>
      <ion-header>
        <ion-toolbar class="header-toolbar">
          <ion-buttons slot="start">
            <ion-menu-button color="dark"></ion-menu-button>
          </ion-buttons>
          <ion-title>
            <tabs-header-dropdown />
          </ion-title>
          <ion-buttons
            slot="end"
          >
            <ion-button @click="openWelcomeModal" v-if="!authStore.isConvenor">
              <ion-icon color="dark" :icon="cogOutline"></ion-icon>
            </ion-button>
            <announcement-notification-component v-if="leagueStore.league.announcements.length" />
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">

          <ion-tab-button
            tab="dashboard"
            v-if="authStore.isConvenor || (!authStore.isConvenor && leagueStore.league.hide_dashboard_tab === 1)"
            :href="generateURL('dashboard')"
            :class="route.fullPath === generateURL('dashboard') ? 'tab-selected' : ''"
            @click="router.push(generateURL('dashboard'))"
          >
            <ion-icon
              :icon="baseballOutline"
              :color="route.fullPath === generateURL('dashboard') ? 'dark' : ''"
            />
            <ion-label
              :color="route.fullPath === generateURL('dashboard') ? 'dark' : ''"
              >Dashboard</ion-label
            >
          </ion-tab-button>
          <ion-tab-button
            v-if="leagueStore.league.hide_schedule_tab !== 1"
            tab="schedule"
            :href="generateURL('schedule')"
            :class="
              route.fullPath === generateURL('schedule') ? 'tab-selected' : ''
            "
            @click="router.push(generateURL('schedule'))"
          >
            <ion-icon
              :icon="calendarOutline"
              :color="route.fullPath === generateURL('schedule') ? 'dark' : ''"
            />
            <ion-label
              :color="route.fullPath === generateURL('schedule') ? 'dark' : ''"
              >Schedule</ion-label
            >
          </ion-tab-button>

          <ion-tab-button
            v-if="leagueStore.league.hide_standings_tab !== 1"
            tab="standings"
            :href="generateURL('standings')"
            :class="
              route.fullPath === generateURL('standings') ? 'tab-selected' : ''
            "
            @click="router.push(generateURL('standings'))"
          >
            <ion-icon
              :icon="statsChartOutline"
              :color="route.fullPath === generateURL('standings') ? 'dark' : ''"
            />
            <ion-label
              :color="route.fullPath === generateURL('standings') ? 'dark' : ''"
              >Standings</ion-label
            >
          </ion-tab-button>

          <ion-tab-button
            v-if="authStore.isConvenor && leagueStore.league.hide_teams_tab !== 1"
            tab="teams"
            :href="generateURL('teams')"
            :class="
              route.fullPath === generateURL('teams') ? 'tab-selected' : ''
            "
            @click="router.push(generateURL('teams'))"
          >
            <ion-icon
              :icon="peopleOutline"
              :color="route.fullPath === generateURL('teams') ? 'dark' : ''"
            />
            <ion-label
              :color="route.fullPath === generateURL('teams') ? 'dark' : ''"
              >Teams</ion-label
            >
          </ion-tab-button>

          

          
          <!-- <ion-tab-button
            tab="info"
            :href="generateURL('info')"
            :class="
              route.fullPath === generateURL('info') ? 'tab-selected' : ''
            "
            @click="router.push(generateURL('info'))"
          >
            <ion-icon
              :icon="bookOutline"
              :color="route.fullPath === generateURL('info') ? 'dark' : ''"
            />
            <ion-label
              :color="route.fullPath === generateURL('info') ? 'dark' : ''"
              >Rules</ion-label
            >
          </ion-tab-button> -->
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { defineComponent } from "vue";
import SitewideNotice from "@/components/header/SiteNotice.vue";

import {
  IonTabBar,
  IonTabButton,
  IonButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonContent,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  onIonViewDidEnter,
} from "@ionic/vue";
import {
  calendarOutline,
  // informationCircleOutline,
  peopleOutline,
  statsChartOutline,
  baseballOutline,
  // bookOutline,
  cogOutline
} from "ionicons/icons";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { openModal } from "@/utils/useIonicComponents.js";
import TabsHeaderDropdown from "@/components/TabsHeaderDropdown.vue";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useLeagueStore } from "@/stores/leagueStore";
import WelcomeModal from "@/components/schedule/welcome/WelcomeModal.vue";
import AnnouncementNotificationComponent from "@/components/announcements/AnnouncementNotificationComponent.vue";
import tinycolor from "tinycolor2";
defineComponent({
  name: "TabsPage",
});
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
if (leagueStore.league.primary_color) {
  window.document.body.style.setProperty(
    "--ion-color-primary",
    leagueStore.league.primary_color
  );
  const c = tinycolor(leagueStore.league.primary_color);
  window.document.body.style.setProperty(
    "--ion-color-primary-tint",
    c.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-primary-shade",
    c.darken().toString()
  );
}
if (leagueStore.league.secondary_color) {
  window.document.body.style.setProperty(
    "--ion-color-secondary",
    leagueStore.league.secondary_color
  );
  const c = tinycolor(leagueStore.league.secondary_color);
  window.document.body.style.setProperty(
    "--ion-color-secondary-tint",
    c.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-secondary-shade",
    c.darken().toString()
  );
}
function generateURL(tabName) {
  const link = `/${
    leagueStore.league?.type ? leagueStore.league.type : "league"
  }/${route.params.leagueSlug}/${tabName}`;
  const getQueryParams = route.fullPath.split("?")[1];

  if (getQueryParams) {
    return link + "?" + getQueryParams;
  }
  return link;
}
onIonViewDidEnter(() => {
  if (
    !authStore.isConvenor &&
    !preferenceStore.userWelcomeModalDismissed.includes(leagueStore.league.id)
  ) {
    openModal(WelcomeModal, "welcomeModal");
  }
  preferenceStore.setAsRecentEvents();
});

function openWelcomeModal () {
  openModal(WelcomeModal, "welcomeModal");
}
</script>

<style>
.alert-button-logout {
  color: var(--ion-color-danger) !important;
}
.welcomeModal {
  --height: 100%;
  --border-radius: 0%;
}
.header-toolbar {
  --background: var(--ion-color-primary);
}

.tab-selected {
  background: var(--ion-color-primary);
}

#chatbase-bubble-button {
  display: none !important;
}
</style>

import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import Papa from "papaparse";
// import { useLeagueStore } from "@/stores/leagueStore";
import MergeLocations from "@/components/schedule/upload-csv/MergeLocations.vue";
import FixColumns from "@/components/schedule/upload-csv/FixColumns.vue";
import { showToast, showLoading, showAlert } from "@/utils/useIonicComponents";
import { useNewGameStore } from "@/stores/newGameStore";
import { useLeagueStore } from "@/stores/leagueStore";
import { useAuthStore } from "@/stores/authStore";
import { uniq } from "lodash";
import { parseDate, parseTime } from "@/utils/useDateTimeFunctions";
import { diceCoefficient } from "dice-coefficient";
import { lancasterStemmer as stemmer } from "lancaster-stemmer";

export const useUploadCSVStore = defineStore("UploadCSV", () => {
  const authStore = useAuthStore();
  const leagueStore = useLeagueStore();

  const fileData = ref();
  const savedCSVData = reactive(
    JSON.parse(localStorage.getItem("savedCSVData")) || {}
  );
  const columnAssociation = ref();
  const saveCSVAssociation = reactive(
    JSON.parse(localStorage.getItem("saveCSVAssociation")) || {}
  );
  const userChoiceForLoadingPreviousData = ref(false);

  const defaultTemplateKeys = [
    "division_name",
    "park_name",
    "diamond_signifier",
    "start_date",
    "start_time",
    "end_time",
    "away_team",
    "home_team",
  ];

  async function parseCSV(file) {
    const loading = await showLoading("Parsing CSV. Please Wait...");
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        fileData.value = results.data;
        for (const data of fileData.value) {
          for (const key of Object.keys(data)) {
            data[key] = data[key].trim();
          }
        }
        loading.dismiss();
        associateHeaders();
        // const ionNav = document.querySelector("ion-nav");
        // ionNav.push(AssociateColumns);
      },
      error: function (error) {
        console.log(error);
        loading.dismiss();
        showToast("Error Parsing CSV. Please try again!", "danger");
      },
    });
  }

  async function saveCSVData() {
    if (!savedCSVData[authStore.isLoggedIn]) {
      savedCSVData[authStore.isLoggedIn] = {};
    }
    savedCSVData[authStore.isLoggedIn][leagueStore.league.id] = fileData.value;
    localStorage.setItem("savedCSVData", JSON.stringify(savedCSVData));
  }

  async function saveAssociation() {
    if (!saveCSVAssociation[authStore.isLoggedIn]) {
      saveCSVAssociation[authStore.isLoggedIn] = {};
    }
    saveCSVAssociation[authStore.isLoggedIn][leagueStore.league.id] =
      columnAssociation.value;
    localStorage.setItem(
      "saveCSVAssociation",
      JSON.stringify(saveCSVAssociation)
    );
  }

  const checkForSavedAssociation = computed(() => {
    if (saveCSVAssociation[authStore.isLoggedIn]) {
      return saveCSVAssociation[authStore.isLoggedIn][leagueStore.league.id];
    }
    return {};
  });

  function loadPreviousData() {
    fileData.value = savedCSVData[authStore.isLoggedIn][leagueStore.league.id];
    try {
      columnAssociation.value =
        saveCSVAssociation[authStore.isLoggedIn][leagueStore.league.id];
    } catch (err) {
      return;
    }
  }

  async function associateHeaders() {
    saveCSVData(); //save the parsed data to local storage
    const isDefaultTemplate = defaultTemplateKeys.every((key) =>
      getColumns.value.includes(key)
    );
    if (isDefaultTemplate) {
      //if the user uploads SPC template CSV
      showToast("Slo-Pitch Central Template Detected.", "success", 1000);
      columnAssociation.value = {};
      for (const key of defaultTemplateKeys) {
        columnAssociation.value[key] = key;
      }
      //these three data labels differs in CSV template and implementation in api
      columnAssociation.value["select_away_team"] = "away_team";
      columnAssociation.value["select_home_team"] = "home_team";
      columnAssociation.value["division_id"] = "division_name";

      delete columnAssociation.value["away_team"];
      delete columnAssociation.value["home_team"];
      delete columnAssociation.value["division_name"];

      console.log(columnAssociation.value);
      if ((await validateData()) && validateDateTime()) {
        const ionNav = document.querySelector("ion-nav");
        ionNav.push(MergeLocations);
      }
    } else {
      // if the user uploads a custom template csv, we try to guess the headers
      if (Object.keys(fileData.value[0]).length < defaultTemplateKeys.length) {
        //check if the csv has atleast the same number of columns as the default spc template
        await showAlert({
          header: "Invalid CSV file.",
          message: `The CSV should contain atleast ${defaultTemplateKeys.length} columns`,
          buttons: [
            {
              text: "OK",
              role: "confirm",
            },
          ],
        });
        return 0;
      } else {
        const guessedAssociation = mapHeaders(Object.keys(fileData.value[0]));
        columnAssociation.value = {};
        for (const key of defaultTemplateKeys) {
          columnAssociation.value[key] = guessedAssociation[key];
        }
        //these three data labels differs in CSV template and implementation in api
        columnAssociation.value["select_away_team"] =
          guessedAssociation["away_team"];
        columnAssociation.value["select_home_team"] =
          guessedAssociation["home_team"];
        columnAssociation.value["division_id"] =
          guessedAssociation["division_name"];

        delete columnAssociation.value["away_team"];
        delete columnAssociation.value["home_team"];
        delete columnAssociation.value["division_name"];

        console.log(columnAssociation.value);
        const ionNav = document.querySelector("ion-nav");
        ionNav.push(FixColumns);
      }
    }
  }

  async function validateData() {
    const errorIndex = [];
    for (const index in fileData.value) {
      for (const key of Object.values(columnAssociation.value)) {
        if (!fileData.value[index][key]) {
          //check if the value for each key exists
          if (errorIndex.indexOf(index) === -1) {
            errorIndex.push(index);
          }
        }
      }
    }

    if (errorIndex.length === fileData.value.length) {
      await showAlert({
        header: "Invalid CSV file.",
        buttons: [
          {
            text: "OK",
            role: "confirm",
          },
        ],
      });
      return 0;
    }
    // remove rows with errors
    errorIndex.sort((a, b) => b - a); // sort in decending order before delete

    for (const index in errorIndex) {
      fileData.value.splice(errorIndex[index], 1);
    }

    if (errorIndex.length) {
      await showAlert({
        header: `Invalid data found in line number ${errorIndex
          .sort((a, b) => a - b)
          .map((index) => Number(index) + 2)
          .join()} of CSV. These lines will not be used`,
        buttons: [
          {
            text: "OK",
            role: "confirm",
          },
        ],
      });
      return 1;
    }
    return 1;
  }

  function validateDateTime() {
    for (const element of fileData.value) {
      if (columnAssociation.value["start_date"]) {
        if (!parseDate(element[columnAssociation.value.start_date])) {
          showToast("Invalid Start Date Format", "danger");
          return false;
        }
      }

      if (columnAssociation.value["start_time"]) {
        if (!parseTime(element[columnAssociation.value.start_time])) {
          showToast("Invalid Start Time Format for start time", "danger");
          return false;
        }
      }

      if (columnAssociation.value["end_time"]) {
        if (!parseTime(element[columnAssociation.value.end_time])) {
          showToast("Invalid End Time Format for start time", "danger");
          return false;
        }
      }
    }
    return true;
  }

  function sendGamesToAddNewGames() {
    const leagueStore = useLeagueStore();
    const newGameStore = useNewGameStore();
    for (const element of fileData.value) {
      // to convert team name to team id
      const away_team = leagueStore.league.teams.find(
        (team) =>
          team.name === element[columnAssociation.value.select_away_team] &&
          team.division_name === element[columnAssociation.value.division_id]
      );
      if (away_team) {
        element[columnAssociation.value.select_away_team] = away_team.id;
      }
      const home_team = leagueStore.league.teams.find(
        (team) =>
          team.name === element[columnAssociation.value.select_home_team] &&
          team.division_name === element[columnAssociation.value.division_id]
      );
      if (home_team) {
        element[columnAssociation.value.select_home_team] = home_team.id;
      }
    }
    newGameStore.importGames(fileData.value, columnAssociation.value);
    columnAssociation.value = {};
  }

  const getColumns = computed(() => {
    if (fileData.value?.length) {
      return Object.keys(fileData.value[0]);
    } else {
      return {};
    }
  });

  const getTeams = computed(() => {
    if (columnAssociation.value) {
      const away_teams = fileData.value.map((item) => {
        // console.log(item, item[columnAssociation.value.select_away_team]);
        return {
          name: item[columnAssociation.value.select_away_team],
          division: item[columnAssociation.value.division_id],
        };
      });
      const home_teams = fileData.value.map((item) => {
        return {
          name: item[columnAssociation.value.select_home_team],
          division: item[columnAssociation.value.division_id],
        };
      });
      // console.log(columnAssociation.value, away_teams);
      return away_teams.concat(home_teams);
    } else {
      return [];
    }
  });

  const getParks = computed(() => {
    if (columnAssociation.value) {
      return uniq(
        fileData.value.map((item) => item[columnAssociation.value.park_name])
      );
    } else {
      return [];
    }
  });

  const getDiamonds = computed(() => {
    if (columnAssociation.value) {
      return uniq(
        fileData.value.map(
          (item) => item[columnAssociation.value.diamond_signifier]
        )
      );
    } else {
      return [];
    }
  });

  const extractDataForSelect = computed(() => {
    // this function will transfrom the csv array of objects to
    // an object with keys with all data merged
    // this will help pick the correct data for association
    const dataForSelect = {};
    for (let data of fileData.value) {
      const keys = Object.keys(data);
      for (let key of keys) {
        if (!dataForSelect[key]) {
          dataForSelect[key] = [];
        }
        dataForSelect[key].push(data[key]);
      }
    }
    for (let key in dataForSelect) {
      dataForSelect[key] = uniq(dataForSelect[key]);
      if (dataForSelect[key][0] === "") {
        delete dataForSelect[key];
      }
    }
    return dataForSelect;
  });

  const isTherePreviousFileData = computed(() => {
    try {
      if (savedCSVData[authStore.isLoggedIn][leagueStore.league.id]) {
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  });

  function generateVariations(baseHeader, synonyms) {
    const variations = [
      baseHeader,
      baseHeader.toLowerCase(),
      baseHeader.toUpperCase(),
      baseHeader.replace(/_/g, " "),
      baseHeader.replace(/\s/g, "_"),
    ];

    // Adding stemmed versions of the base header
    const stemmedBaseHeader = stemmer(baseHeader);
    variations.push(
      stemmedBaseHeader,
      stemmedBaseHeader.toLowerCase(),
      stemmedBaseHeader.toUpperCase(),
      stemmedBaseHeader.replace(/_/g, " "),
      stemmedBaseHeader.replace(/\s/g, "_")
    );

    // Adding synonyms and their variations
    synonyms.forEach((synonym) => {
      const stemmedSynonym = stemmer(synonym);
      variations.push(
        synonym,
        synonym.toLowerCase(),
        synonym.toUpperCase(),
        synonym.replace(/_/g, " "),
        synonym.replace(/\s/g, "_"),
        stemmedSynonym,
        stemmedSynonym.toLowerCase(),
        stemmedSynonym.toUpperCase(),
        stemmedSynonym.replace(/_/g, " "),
        stemmedSynonym.replace(/\s/g, "_")
      );
    });

    return variations;
  }

  function mapHeaders(csvHeaders) {
    const standardHeadersWithSynonyms = {
      division_name: [
        "division",
        "tier",
        "pod",
        "section",
        "part",
        "segment",
        "department",
        "unit",
        "category",
      ],
      park_name: [
        "park",
        "venue",
        "woods",
        "recreation_area",
        "playground",
        "play_area",
        "green_space",
        "public_garden",
        "nature_reserve",
      ],
      diamond_signifier: [
        "diamond",
        "field_signifier",
        "diamond_code",
        "field_marker",
        "pitch_identifier",
      ],
      start_date: [
        "date",
        "begin_date",
        "opening_date",
        "commencement_date",
        "initiation_date",
        "launch_date",
        "inception_date",
      ],
      start_time: [
        "time",
        "start",
        "begin_time",
        "opening_time",
        "commencement_time",
        "initiation_time",
        "launch_time",
        "inception_time",
      ],
      end_time: [
        "end",
        "finish_time",
        "closing_time",
        "conclusion_time",
        "termination_time",
        "end_period",
        "final_time",
      ],
      away_team: [
        "away",
        "visitors",
        "visiting_team",
        "guest_team",
        "opponent",
        "challenger",
        "competitor",
        "rival",
        "adversary",
      ],
      home_team: [
        "home",
        "host_team",
        "local_team",
        "resident_team",
        "home_side",
        "domestic_team",
        "native_team",
        "home_squad",
      ],
    };

    let headerMatches = [];
    let usedCsvHeaders = new Set();

    Object.keys(standardHeadersWithSynonyms).forEach((internalHeader) => {
      let bestMatch = {
        internalHeader: internalHeader,
        csvHeader: null,
        score: 0,
      };
      const variations = generateVariations(
        internalHeader,
        standardHeadersWithSynonyms[internalHeader] || []
      );

      csvHeaders.forEach((csvHeader) => {
        if (!usedCsvHeaders.has(csvHeader)) {
          variations.forEach((variation) => {
            const score = diceCoefficient(
              csvHeader.toLowerCase(),
              variation.toLowerCase()
            );
            if (score > bestMatch.score) {
              bestMatch = {
                internalHeader: internalHeader,
                csvHeader: csvHeader,
                score: score,
              };
            }
          });
        }
      });

      headerMatches.push(bestMatch);
    });

    // Sort matches by score in descending order
    headerMatches.sort((a, b) => b.score - a.score);

    let mappedHeaders = {};
    headerMatches.forEach((match) => {
      if (match.score > 0.5 && !usedCsvHeaders.has(match.csvHeader)) {
        // Adjust this threshold
        mappedHeaders[match.internalHeader] = match.csvHeader;
        usedCsvHeaders.add(match.csvHeader);
      } else {
        mappedHeaders[match.internalHeader] = null; // unmatched
      }
    });

    return mappedHeaders;
  }

  return {
    fileData,
    columnAssociation,
    getColumns,
    getTeams,
    isTherePreviousFileData,
    getParks,
    getDiamonds,
    extractDataForSelect,
    checkForSavedAssociation,
    userChoiceForLoadingPreviousData,
    parseCSV,
    saveCSVData,
    saveAssociation,
    sendGamesToAddNewGames,
    validateDateTime,
    validateData,
    loadPreviousData,
    associateHeaders,
  };
});

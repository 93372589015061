<template>
  <ion-list>
    <!-- <ion-list-header 
      color="primary"
      v-if="recentEvents.length > 0"
      >
        Recently Visited Events
      </ion-list-header
    > -->
    <h1
      style="
        color: var(--ion-color-dark);
        text-align: center;
        text-transform: uppercase;
      "
    >
      <span style="background-color: var(--ion-color-primary); padding: 0 10px">
        Your Events
      </span>
    </h1>
    <span v-if="authStore.isLoggedIn">
      <!-- <ion-list-header><ion-label>Your Events</ion-label></ion-list-header> -->
      <ion-item v-if="userEvents?.length > 5">
        <ion-searchbar v-model="searchTerm" placeholder="Search your events" />
      </ion-item>
      <ion-item
        button
        @click="
          $emit('eventFound', {
            slug: event.slug,
            type: event.type,
          })
        "
        v-for="event of filteredEvents"
        :key="event.id"
        ><ion-label> {{ event.name.toUpperCase() }}</ion-label></ion-item
      >
      <ion-item v-if="searchTerm && filteredEvents.length === 0"
        ><ion-label>{{ searchTerm }} not found</ion-label></ion-item
      >
      <ion-item v-if="!userEvents?.length"
        ><ion-label>{{ authStore.user?.full_name }} has no Events</ion-label
        ><ion-button @click="openNewEventModal"
          >Create New League/Tournament</ion-button
        ></ion-item
      >
    </span>
    <ion-list-header v-if="preferenceStore.userShortCodes.length > 0"
      ><ion-label>Events from your teams</ion-label></ion-list-header
    >
    <IonItem
      button
      v-for="shortcode in preferenceStore.userShortCodes"
      :key="shortcode.shortcode"
      @click="
        emit('eventFound', {
          slug: shortcode.leagueSlug,
          type: shortcode.leagueType,
        })
      "
      ><ion-label>{{ shortcode.leagueName.toUpperCase() }}</ion-label></IonItem
    >
  </ion-list>
</template>

<script setup>
import { defineComponent, ref, defineEmits, computed, watchEffect } from "vue";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonSearchbar,
  IonList,
  IonListHeader,
} from "@ionic/vue";
import { useAuthStore } from "@/stores/authStore";
import { api } from "@/utils/axios";
import { openModal } from "@/utils/useIonicComponents.js";
import { usePreferenceStore } from "@/stores/preferenceStore";
import NewEventModal from "@/components/event/NewEventModal.vue";
import Fuse from "fuse.js";
// import { useRouter } from "vue-router";
// import { useLeagueStore } from "@/stores/leagueStore";

defineComponent({
  name: "UserEvents",
});
const emit = defineEmits(["userNoEvent", "eventFound"]);

// const router = useRouter();
// const route = useRoute();
const authStore = useAuthStore();
const preferenceStore = usePreferenceStore();
// const leagueStore = useLeagueStore();

const userEvents = ref(null);
const loading = ref(false);
const searchTerm = ref(null);

watchEffect(() => {
  if (authStore.isLoggedIn) {
    getUserEvents();
  }
});

async function getUserEvents() {
  loading.value = true;
  const results = await api.get(`/api/your-events/${authStore.user.id}`);
  userEvents.value = results.data;
  if (!userEvents.value.length) {
    emit("userNoEvent");
  }
  loading.value = false;
}
async function openNewEventModal() {
  const response = await openModal(NewEventModal, "newEventModal");
  if (response === true) {
    getUserEvents();
  }
}

const filteredEvents = computed(() => {
  if (searchTerm.value) {
    const fuse = new Fuse(userEvents.value, {
      keys: ["slug", "name"],
    });
    return fuse.search(searchTerm.value).map((items) => items.item);
  }
  return userEvents.value;
});
</script>

<style scoped>
ion-item {
  --background: #191919;
}
</style>

<template>
  <ion-button
    style="margin-top: 0.7rem"
    expand="block"
    @click="openRegisterModal"
    >Register</ion-button
  >
</template>
<script setup>
import { defineComponent } from "vue";
import { IonButton } from "@ionic/vue";
import RegisterModal from "@/components/register/RegisterModal.vue";
import { openModal } from "@/utils/useIonicComponents.js";

defineComponent({
  name: "RegisterComponent",
});

async function openRegisterModal() {
  await openModal(RegisterModal, "registerModal");
}
</script>
